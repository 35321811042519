import { WhiteNavBar } from "./components/white-navbar/WhiteNavBar";
import { AppRoutes } from "./routes/AppRoutes";

function App() {
  return (
    <div>
      {/* <WhiteNavBar /> */}
      <AppRoutes />
    </div>
  );
}

export default App;
