import React from 'react'
import './CateringIntro.scss'

export const CateringIntro = () => {
  return (
    <div className='catering-intro-out-container'>
        
    </div>
  )
}
